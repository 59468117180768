

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import schoolsData from '../schoolsData.json';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabaseUrl = 'https://mmyfyclfttmppuxxtztg.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1teWZ5Y2xmdHRtcHB1eHh0enRnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYyMTY0MDIsImV4cCI6MjA1MTc5MjQwMn0.dmtItQQru0HX7HDR-0Sa4LtED_oYfYA_c9epwtU-CbU';
  
const supabase = createClient(supabaseUrl, supabaseKey);

const ContactForm = () => {
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [schools, setSchools] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isManualEntry, setIsManualEntry] = useState(false);
  //const [jumlahMurid, setJumlahMurid] = useState("");

  useEffect(() => {
    const stateList = Object.keys(schoolsData);
    setStates(stateList);
    const defaultState = stateList.includes("selangor") ? "selangor" : stateList[0];
    setSelectedState(defaultState);
  }, []);

  useEffect(() => {
    if (selectedState) {
      setSchools(schoolsData[selectedState] || []);
      setSearchTerm("");
      setSelectedSchool("");
      setIsManualEntry(false);
    }
  }, [selectedState]);

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setErrors({ ...errors, state: "" });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setSelectedSchool(value);
    setErrors({ ...errors, school: "" });

    if (value.length > 1 && !isManualEntry) {
      const filteredSuggestions = schools.filter(school =>
        school.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions.slice(0, 5));
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (school) => {
    setSelectedSchool(school);
    setSearchTerm(school);
    setSuggestions([]);
    setErrors({ ...errors, school: "" });
  };

  const toggleManualEntry = () => {
    setIsManualEntry(!isManualEntry);
    setSearchTerm("");
    setSelectedSchool("");
    setSuggestions([]);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 11) {
      let formattedNumber = '';
      if (value.length > 3) {
        formattedNumber += value.slice(0, 3) + '-';
        if (value.length > 7) {
          formattedNumber += value.slice(3, 7) + '-';
          formattedNumber += value.slice(7);
        } else {
          formattedNumber += value.slice(3);
        }
      } else {
        formattedNumber = value;
      }
      setPhoneNumber(formattedNumber);
      setErrors({ ...errors, phoneNumber: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
  
    if (!name.trim()) newErrors.name = "Nama diperlukan";
    if (!selectedState) newErrors.state = "Negeri diperlukan";
    if (!selectedSchool) newErrors.school = "Sekolah diperlukan";
    if (!phoneNumber) newErrors.phoneNumber = "Nombor telefon diperlukan";
    if (!position) newErrors.position = "Jawatan diperlukan";
    //if (!jumlahMurid) newErrors.jumlahMurid = "Jumlah murid diperlukan";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setIsSubmitting(true);
      setSubmitError(null);
      setSubmitSuccess(false);
  
      try {
        const { data, error } = await supabase
          .from('contact_submissions')
          .insert([
            {
              name,
              state: selectedState,
              school: selectedSchool,
              phone_number: phoneNumber,
              position,
              //jumlah_murid: jumlahMurid
            }
          ]);
  
        if (error) throw error;
  
        console.log("Form submitted successfully:", data);
        setSubmitSuccess(true);
  
        // Reset form after submission
        setName("");
        setSelectedState("");
        setSelectedSchool("");
        setSearchTerm("");
        setPhoneNumber("");
        setPosition("");
        setErrors({});
  
        // Prepare WhatsApp message
        //const message = `Salam, Nama: ${name}%0ANegeri: ${selectedState}%0ASekolah: ${selectedSchool}%0ANombor Telefon: ${phoneNumber}%0AJawatan: ${position}%0AJumlah Murid: ${jumlahMurid}`;
        const message = 
        `Assalamualaikum dan Salam Sejahtera,

*Saya berminat untuk mengetahui lebih lanjut berkenaan sistem pss zabra solutions*

         Nama: ${name}
         Sekolah: ${selectedSchool}
         Negeri: ${selectedState}
         No. Telefon: ${phoneNumber}
         Jawatan: ${position}`
         //Jumlah Murid: ${jumlahMurid}`
         .replace(/\n/g, '%0A');

        // Your WhatsApp number (replace with your actual number)
        const whatsappNumber = "60173634766";
  
        // Redirect to WhatsApp
        window.location.href = `https://wa.me/${whatsappNumber}?text=${message}`;
  
      } catch (error) {
        console.error("Error submitting form:", error);
        setSubmitError(error.message || "Terdapat masalah semasa menghantar borang. Sila cuba lagi.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="bg-[#F29200] text-white py-10">
      <div className="container mx-auto px-4">
        <div className="max-w-md mx-auto">
          <h2 className="text-xl font-bold mb-4 text-center">
            
          </h2>
          {submitSuccess ? (
            <div className="bg-green-500 text-white p-4 rounded mb-4">
              Borang berjaya dihantar! Anda akan dialihkan ke halaman utama dalam masa 3 saat.
            </div>
          ) : submitError ? (
            <div className="bg-red-500 text-white p-4 rounded mb-4">
              {submitError}
            </div>
          ) : null}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block mb-2 font-semibold">Nama:</label>
              <input
                id="name"
                type="text"
                placeholder="Masukkan nama anda"
                className={`w-full p-2 rounded text-gray-800 ${errors.name ? 'border-red-500' : ''}`}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setErrors({ ...errors, name: "" });
                }}
                required
              />
              {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="state" className="block mb-2 font-semibold">Negeri:</label>
              <select
                id="state"
                className={`w-full p-2 rounded text-gray-800 ${errors.state ? 'border-red-500' : ''}`}
                value={selectedState}
                onChange={handleStateChange}
                required
              >
                <option value="" disabled>Pilih Negeri</option>
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state.charAt(0).toUpperCase() + state.slice(1)}
                  </option>
                ))}
              </select>
              {errors.state && <p className="text-red-500 text-sm mt-1">{errors.state}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="school" className="block mb-2 font-semibold">Sekolah:</label>
              <div className="relative">
                <input
                  id="school"
                  type="text"
                  placeholder={isManualEntry ? "Masukkan nama sekolah" : "Cari Sekolah"}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className={`w-full p-2 rounded text-gray-800 ${errors.school ? 'border-red-500' : ''}`}
                  required
                />
                {!isManualEntry && suggestions.length > 0 && (
                  <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded mt-1">
                    {suggestions.map((school, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(school)}
                        className="p-2 hover:bg-gray-100 cursor-pointer text-gray-800"
                      >
                        {school}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {errors.school && <p className="text-red-500 text-sm mt-1">{errors.school}</p>}
              <button
                type="button"
                onClick={toggleManualEntry}
                className="mt-2 text-sm underline focus:outline-none text-left"
              >
                {isManualEntry ? "Cari dari senarai sekolah" : "Tidak jumpa sekolah anda? Klik di sini untuk masukkan secara manual"}
              </button>
            </div>
            <div className="mb-4">
              <label htmlFor="phone" className="block mb-2 font-semibold">Nombor Telefon:</label>
              <input
                id="phone"
                type="tel"
                placeholder="e.g., 012-3456-7890"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                className={`w-full p-2 rounded text-gray-800 ${errors.phoneNumber ? 'border-red-500' : ''}`}
                required
              />
              {errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="position" className="block mb-2 font-semibold">Jawatan:</label>
              <select
                id="position"
                className={`w-full p-2 rounded text-gray-800 ${errors.position ? 'border-red-500' : ''}`}
                value={position}
                onChange={(e) => {
                  setPosition(e.target.value);
                  setErrors({ ...errors, position: "" });
                }}
                required
              >
                <option value="" disabled>Pilih Jawatan</option>
                <option value="Pentadbir">Pentadbir</option>
                <option value="Guru Pusat Sumber">Guru Pusat Sumber</option>
              </select>
              {errors.position && <p className="text-red-500 text-sm mt-1">{errors.position}</p>}
            </div>

            {/* <div className="mb-4">
            <label htmlFor="jumlahMurid" className="block mb-2 font-semibold">Jumlah Murid:</label>
            <select
              id="jumlahMurid"
              className={`w-full p-2 rounded text-gray-800 ${errors.jumlahMurid ? 'border-red-500' : ''}`}
              value={jumlahMurid}
              onChange={(e) => {
                setJumlahMurid(e.target.value);
                setErrors({ ...errors, jumlahMurid: "" });
              }}
              required
            >
              <option value="" disabled>Pilih Jumlah Murid</option>
              <option value="301-500">301-500</option>
              <option value="501-999">501-999</option>
              <option value=">1000">&gt;1000</option>
            </select>
            {errors.jumlahMurid && <p className="text-red-500 text-sm mt-1">{errors.jumlahMurid}</p>}
          </div> */}


            <button
              type="submit"
              className="w-full bg-white text-[#F29200] py-2 px-6 rounded-full font-semibold hover:bg-gray-100 transition duration-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Menghantar...' : 'Hantar'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;