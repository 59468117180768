

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { motion } from 'framer-motion';
// import ImageGallery from '../components/ImageGallery';
// import { ChevronDown } from 'lucide-react';

// const HomePage = () => {
//   const [hoveredCard, setHoveredCard] = useState(null);

//   const offerings = [
//     {
//       title: "Pengurusan Automasi Pusat Sumber",
//       description: "Sistem pengurusan komprehensif untuk pusat sumber sekolah",
//       defaultImage: "/pengurusan sistem zabra_1 bnw.png",
//       hoverImage: "/pengurusan sistem zabra_1.png"
//     },
//     {
//       title: "Kad ID Murid dan Guru",
//       description: "Kad ID yang boleh disesuaikan untuk murid dan guru",
//       defaultImage: "/kad matrik murid zabra_1 bnw.png",
//       hoverImage: "/kad matrik murid zabra_1.png"
//     },
//     {
//       title: "Servis Baiki Komputer",
//       description: "Perkhidmatan pembaikan pakar untuk semua jenis komputer",
//       defaultImage: "/baiki komputer zabra_1 bnw.png",
//       hoverImage: "/baiki komputer zabra_1.png"
//     }
//   ];

//   const services = [
//     { title: "Carian LAN-OPAC", description: "Sistem carian buku dalam talian untuk pustakawan dan murid", image: "/icons/search.png" },
//     { title: "Pengurusan Bahan Buku dan Murid", description: "Pengurusan efisien untuk bahan bacaan dan rekod murid", image: "/icons/daftar bahan zabra.png" },
//     { title: "Operasi Pinjam Pulang", description: "Sistem pinjaman dan pemulangan buku yang mudah dan cepat", image: "/icons/operasi pinjam pulang zabra.png" },
//     { title: "Pengurusan Inventori", description: "Pengurusan inventori yang tepat dan kemas kini", image: "/icons/inventori buku zabra.png" },
//     { title: "Laporan dan Statistik", description: "Penjanaan laporan terperinci dan statistik penggunaan", image: "/icons/report analysis zabra.png" },
//     { title: "Sistem Kehadiran Pusat Sumber", description: "Pemantauan kehadiran murid di pusat sumber", image: "/icons/sistem kehadiran zabra.png" }
//   ];

//   const scrollToNextSection = () => {
//     const nextSection = document.getElementById('kami-tawarkan');
//     if (nextSection) {
//       nextSection.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   return (
//     <div className="bg-white">

// <section className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-gray-100 to-white py-5 px-4">
//         <div className="container mx-auto">
//           <div className="flex flex-col md:flex-row items-center md:justify-between">
//             <div className="md:w-1/2 text-center md:text-left mb-8 md:mb-0 md:pr-12">
//               <motion.h1 
//                 initial={{ opacity: 0, y: -20 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.8 }}
//                 className="text-3xl md:text-6xl font-bold mb-6 text-[#F29200] leading-tight"
//               >
//                 Memudahkan Operasi Pusat Sumber Sekolah
//               </motion.h1>
//               <motion.p 
//                 initial={{ opacity: 0, y: -20 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.8, delay: 0.2 }}
//                 className="text-xl mb-8 text-gray-700"
//               >
//                 Menyediakan sistem Inovatif, mesra pengguna dan seronok bagi murid-murid sekolah
//               </motion.p>
//               <motion.div
//                 initial={{ opacity: 0, y: -20 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.8, delay: 0.4 }}
//               >
//                 <Link to="/contact" className="bg-[#F29200] text-white py-3 px-8 rounded-full font-semibold hover:bg-[#D97E00] transition duration-300 text-lg inline-block">
//                   Hubungi Kami
//                 </Link>
//               </motion.div>
//             </div>
//             <motion.div 
//               initial={{ opacity: 0, scale: 0.9 }}
//               animate={{ opacity: 1, scale: 1 }}
//               transition={{ duration: 0.8, delay: 0.6 }}
//               className="md:w-1/2 mt-8 md:mt-0 md:pl-12"
//             >
//               <img src="/ZABRA-MAIN.jpeg" alt="Company overview" className="rounded-lg shadow-xl w-full max-w-3xl mx-auto" />
//             </motion.div>
//           </div>
//         </div>
//         <motion.div
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.8, delay: 1 }}
//           className="mt-12 animate-bounce"
//           onClick={scrollToNextSection}
//         >
//           <ChevronDown size={48} className="text-[#F29200] cursor-pointer" />
//         </motion.div>
//       </section>

//       <section className="py-5 bg-gray-100">
//         <div className="container mx-auto px-4">
//           {/* <h2 className="text-3xl font-bold mb-12 text-center text-[#F29200]">Gallery</h2> */}
//           <ImageGallery />
//         </div>
//       </section>

//       <section id="kami-tawarkan" className="pt-20 pb-5 bg-gray-100">
//         <div className="container mx-auto px-4">
//           <h2 className="text-3xl font-bold mb-12 text-center text-[#F29200]">KAMI MENYEDIAKAN</h2>
//           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
//             {offerings.map((offering, index) => (
//               <motion.div 
//                 key={index} 
//                 className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300 flex flex-col"
//                 onMouseEnter={() => setHoveredCard(index)}
//                 onMouseLeave={() => setHoveredCard(null)}
//                 whileHover={{ scale: 1.05 }}
//                 initial={{ opacity: 0, y: 20 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.5, delay: index * 0.1 }}
//               >
//                 <div className="mb-4 flex-grow flex items-center justify-center h-48 ">
//                   <img 
//                     src={hoveredCard === index ? offering.hoverImage : offering.defaultImage} 
//                     alt={offering.title}
//                     className="max-w-full max-h-full object-contain transition-all duration-300 "
//                   />
//                 </div>
//                 <h3 className="text-xl font-semibold mb-4 text-[#F29200] text-center">{offering.title}</h3>
//                 <p className="text-gray-600">{offering.description}</p>
//               </motion.div>
//             ))}
//           </div>
//           <div className="text-center py-5">
//               <Link to="/contact" className="bg-[#F29200] text-white py-3 px-8 rounded-full font-semibold hover:bg-[#D97E00] transition duration-300 text-lg inline-block">
//                 Hubungi kami
//               </Link>
//           </div>
//         </div>
//       </section>

//       {/* SISTEM ZABRA Section */}
//       <section id="services" className="py-5">
//         <div className="container mx-auto px-4">
//           <h2 className="text-3xl font-bold mb-12 text-center text-[#F29200]">SISTEM ZABRA SOLUTIONS</h2>
//           <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8">
//             {services.map((service, index) => (
//               <div 
//                 key={index} 
//                 className="bg-white p-4 sm:p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300 flex flex-col transform hover:scale-105"
//               >
//                 <div className="mb-3 sm:mb-4 flex-grow flex items-center justify-center h-24 sm:h-32 md:h-48">
//                   <img 
//                     src={service.image} 
//                     alt={service.title}
//                     className="max-w-full max-h-full object-contain"
//                   />
//                 </div>
//                 <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-4 text-[#F29200]">{service.title}</h3>
//                 <p className="text-sm sm:text-base text-gray-600">{service.description}</p>
//               </div>
//             ))}
//           </div>

//           <div className="text-center py-5">
//               <Link to="/contact" className="bg-[#F29200] text-white py-3 px-8 rounded-full font-semibold hover:bg-[#D97E00] transition duration-300 text-lg inline-block">
//                 Hubungi kami
//               </Link>
//           </div>
               
//         </div>
//       </section>

      
//     </div>
//   );
// };

// export default HomePage;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ImageGallery from '../components/ImageGallery';
import { ChevronDown } from 'lucide-react';

const HomePage = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const offerings = [
    {
      title: "Pengurusan Automasi Pusat Sumber",
      description: "Sistem pengurusan komprehensif untuk pusat sumber sekolah",
      defaultImage: "/pengurusan sistem zabra_1 bnw.png",
      hoverImage: "/pengurusan sistem zabra_1.png"
    },
    {
      title: "Kad ID Murid dan Guru",
      description: "Kad ID yang disesuaikan untuk murid dan guru mengikut standard",
      defaultImage: "/kad matrik murid zabra_1 bnw.png",
      hoverImage: "/kad matrik murid zabra_1.png"
    },
    {
      title: "Servis Baiki Komputer",
      description: "Perkhidmatan pembaikan pakar untuk semua jenis komputer",
      defaultImage: "/baiki komputer zabra_1 bnw.png",
      hoverImage: "/baiki komputer zabra_1.png"
    }
  ];

  const services = [
    { title: "Carian LAN-OPAC", description: "Sistem carian buku dalam talian untuk pustakawan dan murid", image: "/icons/search.png" },
    { title: "Pengurusan Bahan Buku dan Murid", description: "Pengurusan efisien untuk bahan bacaan dan rekod murid", image: "/icons/daftar bahan zabra.png" },
    { title: "Operasi Pinjam Pulang", description: "Sistem pinjaman dan pemulangan buku yang mudah dan cepat", image: "/icons/operasi pinjam pulang zabra.png" },
    { title: "Pengurusan Inventori", description: "Pengurusan inventori yang tepat dan kemas kini", image: "/icons/inventori buku zabra.png" },
    { title: "Laporan dan Statistik", description: "Penjanaan laporan terperinci dan statistik penggunaan", image: "/icons/report analysis zabra.png" },
    { title: "Sistem Kehadiran Pusat Sumber", description: "Pemantauan kehadiran murid di pusat sumber", image: "/icons/sistem kehadiran zabra.png" }
  ];

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768); // Assuming 768px as the breakpoint for mobile
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setHoveredCard((prevIndex) => 
          prevIndex === null || prevIndex >= offerings.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000); // Change image every 3 seconds on mobile

      return () => clearInterval(interval);
    } else {
      setHoveredCard(null);
    }
  }, [isMobile, offerings.length]);

  const scrollToNextSection = () => {
    const nextSection = document.getElementById('kami-tawarkan');
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-white">
      <section className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-gray-100 to-white py-5 px-4">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row items-center md:justify-between">
            <div className="md:w-1/2 text-center md:text-left mb-8 md:mb-0 md:pr-12">
              <motion.h1 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="text-3xl md:text-6xl font-bold mb-6 text-[#F29200] leading-tight"
              >
                Memudahkan Operasi Pusat Sumber Sekolah
              </motion.h1>
              <motion.p 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="text-xl mb-8 text-gray-700"
              >
                Menyediakan sistem Inovatif, mesra pengguna dan seronok bagi murid-murid sekolah
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                <Link to="/contact" className="bg-[#25D366] text-white py-3 px-8 rounded-full font-semibold hover:bg-[#128C7E] transition duration-300 text-lg inline-block">
                  Hubungi Kami
                </Link>
              </motion.div>
            </div>
            <motion.div 
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              className="md:w-1/2 mt-8 md:mt-0 md:pl-12"
            >
              <img src="/main.jpeg" alt="Company overview" className="rounded-lg shadow-xl w-full max-w-3xl mx-auto" />
            </motion.div>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 1 }}
          className="mt-12 animate-bounce"
          onClick={scrollToNextSection}
        >
          <ChevronDown size={48} className="text-[#F29200] cursor-pointer" />
        </motion.div>
      </section>

      {/* <section className="py-5 bg-gray-100">
        <div className="container mx-auto px-4">
          <ImageGallery />
        </div>
      </section> */}
      <section className="py-10 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center text-[#F29200]">GALERI SEKOLAH</h2>
          <ImageGallery />
        </div>
      </section>

      <section id="kami-tawarkan" className="pt-20 pb-5 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12 text-center text-[#F29200]">KAMI MENYEDIAKAN</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
            {offerings.map((offering, index) => (
              <motion.div 
                key={index} 
                className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300 flex flex-col"
                onMouseEnter={() => !isMobile && setHoveredCard(index)}
                onMouseLeave={() => !isMobile && setHoveredCard(null)}
                whileHover={{ scale: isMobile ? 1 : 1.05 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="mb-4 flex-grow flex items-center justify-center h-48 ">
                  <img 
                    src={isMobile || hoveredCard === index ? offering.hoverImage : offering.defaultImage} 
                    alt={offering.title}
                    className="max-w-full max-h-full object-contain transition-all duration-300 "
                  />
                </div>
                <h3 className="text-xl font-semibold mb-4 text-[#F29200] text-center">{offering.title}</h3>
                <p className="text-gray-600">{offering.description}</p>
              </motion.div>
            ))}
          </div>
          <div className="text-center py-5">
              <Link to="/contact" className="bg-[#25D366] text-white py-3 px-8 rounded-full font-semibold hover:bg-[#128C7E] transition duration-300 text-lg inline-block">
                Hubungi kami
              </Link>
          </div>
        </div>
      </section>

      <section id="services" className="py-5">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12 text-center text-[#F29200]">SISTEM ZABRA SOLUTIONS</h2>
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8">
            {services.map((service, index) => (
              <div 
                key={index} 
                className="bg-white p-4 sm:p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300 flex flex-col transform hover:scale-105"
              >
                <div className="mb-3 sm:mb-4 flex-grow flex items-center justify-center h-24 sm:h-32 md:h-48">
                  <img 
                    src={service.image} 
                    alt={service.title}
                    className="max-w-full max-h-full object-contain"
                  />
                </div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-4 text-[#F29200]">{service.title}</h3>
                <p className="text-sm sm:text-base text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>

          <div className="text-center py-5">
              <Link to="/contact" className="bg-[#25D366] text-white py-3 px-8 rounded-full font-semibold hover:bg-[#128C7E] transition duration-300 text-lg inline-block">
                Hubungi kami
              </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;